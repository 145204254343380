import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <div id="profile">
        <header>
          <img className="thumb" src="https://avatars.githubusercontent.com/u/1713325?v=4" alt="Krithika Muthukumar" title="krithix" width={180} height={180} />

          <h1>Krithika Muthukumar</h1>

          <aside>
            VP Marketing @ <a class="openai" href="https://openai.com">OpenAI</a>, <span>emoji overuser <span role="img" aria-label="laughing with tears">😂</span><span role="img" aria-label="sparklng heart">💖</span><span role="img" aria-label="party face">🥳</span><span role="img" aria-label="100">💯</span></span>
          </aside>
        </header>

        <section>
          <p>Hey, I'm Krithika—also known as krithix on the internet. Before OpenAI, I led marketing at Retool, and was the first marketer at Stripe.</p>
          
          <p>See all my <span><a href="https://linkedin.com/in/krithix">previous work</a></span>, or check out my <span><a href="https://twitter.com/krithix">tweets</a>.</span></p>
        </section>
      </div>
    </div>
  );
}

export default App;
